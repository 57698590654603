<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="context-content">
						<button
							class="bgColorPrincipal modal-icon-close px-2 py-1 mr-3 mt-3 rounded"
							@click="closeUpdateModal"
						>
							<v-icon color="#fff" size="16"> fas fa-times </v-icon>
						</button>
						<div class="d-flex justify-center">
							<v-scroll-y-transition>
								<v-alert
									type="error"
									dense
									transition="fab-transition"
									style="position: fixed; max-width: 20em"
									v-if="warningText"
								>
									{{ warningText }}
								</v-alert>
							</v-scroll-y-transition>
						</div>
						<v-row>
							<v-col>
								<h1 class="font18pt titleColor font-weight-bold">Update context</h1>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="context-content__search">
								<input
									type="text"
									class="inputs pl-3 font12pt titleColor font-weight-bold"
									placeholder="Name Context"
									v-model="contextName"
								/>
								<button
									class="bgColorPrincipal pt-2 pb-2 pl-3 pr-3 ml-3 rounded"
									@click="updateNameContext()"
									:disabled="buttonDisabled"
									v-bind:class="{ buttonDisabled }"
								>
									<v-icon color="#fff" size="16" v-if="!buttonLoading"> fas fa-pencil-alt </v-icon>
									<v-progress-circular
										:size="16"
										:width="2"
										color="white"
										indeterminate
										v-if="buttonLoading"
									>
									</v-progress-circular>
								</button>
							</v-col>
							<div class="component-content__tabs col">
								<v-tabs
									centered
									slider-color="#00A5FF"
									v-model="model"
									background-color="#f3f4f7"
									height="51"
									grow
									class="component-content__tabs-menu"
								>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Twitter</span
										>
										<span>
											<v-icon size="16" color="#1da1f2">fab fa-twitter</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Facebook</span
										>
										<span>
											<v-icon size="16" color="#1877f2">fab fa-facebook</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Instagram</span
										>
										<span>
											<v-icon size="13" color="#E1306C">fab fa-instagram</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Linkedin</span
										>
										<span>
											<v-icon size="13" color="#0e76a8">fab fa-linkedin</v-icon>
										</span>
									</v-tab>
									<v-tab>
										<span class="font13pt titleColor font-weight-bold text-capitalize mr-1"
											>Newspaper</span
										>
										<span>
											<v-icon size="13" color="#444444">fa-solid fa-newspaper</v-icon>
										</span>
									</v-tab>
								</v-tabs>
								<v-tabs-items v-model="model" class="component-content__tabs__info pb-0">
									<v-tab-item transition="none" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center" v-if="formContext">
											<UpdateTwitterDrivers
												@keyworsUpdate="refreshData($event)"
												:formContext="formContext"
												:UpdateISCDrivers="UpdateISCDrivers"
												@updateLoading="updateLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center" v-if="formContext">
											<UpdateFacebookDrivers
												@keyworsUpdate="refreshData($event)"
												:formContext="formContext"
												:UpdateISCDrivers="UpdateISCDrivers"
												@updateLoading="updateLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center" v-if="formContext">
											<UpdateInstagramDrivers
												@keyworsUpdate="refreshData($event)"
												:formContext="formContext"
												:UpdateISCDrivers="UpdateISCDrivers"
												@updateLoading="updateLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center" v-if="formContext">
											<UpdateLinkedinDrivers
												@keyworsUpdate="refreshData($event)"
												:formContext="formContext"
												:UpdateISCDrivers="UpdateISCDrivers"
												@updateLoading="updateLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
									<v-tab-item transition="fade-transition" reverse-transition="fade-transition">
										<div class="base_content d-flex justify-center align-center" v-if="formContext">
											<UpdateNewspaperDrivers
												@keyworsUpdate="refreshData($event)"
												:formContext="formContext"
												@updateLoading="updateLoading"
												@setWarningMessage="setWarningMessage"
											/>
										</div>
									</v-tab-item>
								</v-tabs-items>
							</div>
						</v-row>
						<!-- <v-row> </v-row> -->
					</div>
					<div class="prog-bar__updateContext">
						<v-progress-linear indeterminate v-if="loading"></v-progress-linear>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import UpdateTwitterDrivers from "@/components/context/UpdateTwitterDrivers";
import UpdateFacebookDrivers from "@/components/context/UpdateFacebookDrivers";
import UpdateInstagramDrivers from "@/components/context/UpdateInstagramDrivers";
import UpdateLinkedinDrivers from "@/components/context/UpdateLinkedinDrivers";
import UpdateNewspaperDrivers from "@/components/context/UpdateNewspaperDrivers";

export default {
	name: "UpdateContext",
	props: ["contextData"],
	components: {
		UpdateTwitterDrivers,
		UpdateFacebookDrivers,
		UpdateInstagramDrivers,
		UpdateLinkedinDrivers,
		UpdateNewspaperDrivers,
	},
	data() {
		return {
			model: "",
			formContext: {
				contextId: "",
				name: "",
				twitterKeywords: [],
				facebookKeywords: [],
				newspaperKeywords: [],
				instagramKeywords: [],
				linkedinKeywords: [],
			},
			UpdateISCDrivers: [],
			loading: false,
			contextName: "",
			buttonDisabled: false,
			buttonLoading: false,
			warningText: "",
		};
	},
	created() {
		this.formContext.contextId = this.contextData.id;
		this.contextName = this.contextData.name;
		this.formContext.name = this.contextData.name;
		const filterTwitterKeywords = this.contextData.keywords.filter(function (ak) {
			return ak.type == "tw";
		});
		const filterFacebookKeywords = this.contextData.keywords.filter(function (ak) {
			return ak.type == "fb";
		});
		const filterInstagranKeywords = this.contextData.keywords.filter(function (ak) {
			return ak.type == "ig";
		});
		const filterLinkedinKeywords = this.contextData.keywords.filter(function (ak) {
			return ak.type == "lk";
		});
		const filterNewspaperKeywords = this.contextData.keywords.filter(function (ak) {
			return ak.type == "nws";
		});
		this.formContext.twitterKeywords = filterTwitterKeywords;
		this.formContext.facebookKeywords = filterFacebookKeywords;
		this.formContext.instagramKeywords = filterInstagranKeywords;
		this.formContext.linkedinKeywords = filterLinkedinKeywords;
		this.formContext.newspaperKeywords = filterNewspaperKeywords;
	},
	methods: {
		async updateNameContext() {
			// TODO: REFRESH DATA ONLY IF CHANGES EXISTS
			try {
				this.buttonLoading = true;
				const {
					data: { updateContext },
				} = await this.$store.dispatch("dashboard/updateContext", {
					context_id: this.formContext.contextId,
					name: this.contextName.trim(),
				});
				if (updateContext.name) {
					this.contextName = updateContext.name;
					this.formContext.name = updateContext.name;
				}
			} catch (error) {
				console.error(error);
			} finally {
				this.buttonLoading = false;
			}
		},
		updateLoading(status) {
			this.loading = status;
		},
		closeUpdateModal() {
			this.$emit("close");
			this.$emit("refreshData");
		},
		setWarningMessage(message) {
			this.warningText = message;
			setTimeout(() => {
				this.warningText = "";
				sessionStorage.removeItem("errorMessage");
			}, 6000);
		},
	},
	watch: {
		contextName: {
			immediate: true,
			handler: function () {
				if (this.contextName.trim().length > 0) {
					this.buttonDisabled = false;
				} else {
					this.buttonDisabled = true;
				}
			},
		},
	},
};
</script>

<style>
.prog-bar__updateContext {
	width: 100%;
	bottom: 0px;
	left: 0px;
	position: absolute;
}
</style>
