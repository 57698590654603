import "./set-public-path";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import singleSpaVue from "single-spa-vue";
import { store, vuetify } from "@isc/styleguide";
import VueGauge from "vue-gauge";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.component("vue-gauge", VueGauge);
Vue.component("apexchart", VueApexCharts);
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    el: "#dashboard",
    router,
    vuetify,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
