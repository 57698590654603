<template>
	<div class="context-box" style="width: 90%;">
		<v-row no-gutters>
			<v-col :sm="12" :md="12" :lg="5" class="mb-0">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
					<v-col cols="12" class="pr-1 pl-1 pt-1">
						<input
							maxlength="40"
							type="text"
							class="input pl-3 font12pt titleColor font-weight-bold w-100"
							placeholder="Term, mention or Hashtag (One at a time)..."
							v-model="newDriverName"
						/>
					</v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="6" :lg="3" class="mb-0">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">Country:</span></v-col>
					<v-col cols="12" class="pr-1 pl-1 pt-1">
						<v-select
							v-model="countrySelected"
							:items="paises"
							full-width
							item-text="name"
							item-value="id"
							solo
							hide-details
							flat
							single-line
							return-object
							background-color="#f1f3f8"
							class="selectCountry font12pt titleColor font-weight-bold"
							item-color="#00a5ff"
						></v-select>
					</v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="6" :lg="4" class="mb-0">
				<v-row no-gutters>
					<v-col cols="12"><span class="invisible_">.</span></v-col>
					<v-col cols="12">
						<div class="d-flex justify-end align-end">
							<v-btn
								depressed
								color="bgColorPrincipal"
								class="mainBtn mx-2"
								:disabled="buttonDisabled"
								@click="addNewDriver()"
							>
								<span class="white--text">Create</span>
							</v-btn>
							<v-btn depressed color="bgColorPrincipal" class="mainBtn" @click="openModal = true">
								<span class="white--text mr-1" style="font-size: 20px;">+</span
								><span class="white--text"> ISC Drivers</span>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row>
			<!-- <div class="pt-1 textError ml-0" v-if="warningText != ''">
        <span><v-icon size="12" color="red">fa fa-exclamation-circle</v-icon> {{ warningText }}</span>
      </div> -->
		</v-row>
		<v-row class="context-box__table">
			<v-col class="px-0">
				<div class="context-box__table__header d-flex">
					<div class="col-12 px-0">
						<span class="font12pt leyendColor">Driver list</span>
					</div>
				</div>
				<div class="bgColorBase context-box__table__body">
					<v-virtual-scroll :bench="10" :items="twDrivers" max-height="168" item-height="42">
						<template v-slot:default="{ item }">
							<v-row>
								<v-col cols="1" align="end">
									<div>
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon
													v-if="item.geo_dashboard"
													size="14"
													color="#a2acc4"
													v-bind="attrs"
													v-on="on"
													>fas fa-globe</v-icon
												>
											</template>
											<span>ISC Driver</span>
										</v-tooltip>
									</div>
								</v-col>
								<v-col cols="5">
									<div
										style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
									>
										<span class="font12pt titleColor ml-10">
											{{ item.term }}
										</span>
									</div>
								</v-col>
								<v-col cols="4">
									<div>
										<span class="font12pt leyendColor">
											{{ getCountryName(item.place_id) }}
										</span>
									</div>
								</v-col>
								<v-col cols="1" align="start">
									<div>
										<v-icon @click="deleteKeyword(item)" size="11" color="#a2acc4"
											>fas fa-trash</v-icon
										>
									</div>
								</v-col>
								<v-col cols="1" align="start">
									<div>
										<v-icon
											v-if="item.active === true"
											size="12"
											color="#00a5ff"
											@click="toggleActive(item, false)"
											>fas fa-eye</v-icon
										>
										<v-icon
											v-if="item.active === false"
											size="12"
											color="#a2acc4"
											@click="toggleActive(item, true)"
											>fas fa-eye</v-icon
										>
									</div>
								</v-col>
							</v-row>
						</template>
					</v-virtual-scroll>
				</div>
			</v-col>
		</v-row>
		<AddIscDriverModal v-if="openModal" @closeIscDriverModal="closeIscDriverModal" :driverType="'tw'" />
	</div>
</template>

<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";

export default {
	name: "UpdateTwitterDrivers",
	props: ["formContext", "UpdateISCDrivers"],
	components: { AddIscDriverModal },
	data() {
		return {
			client_id: null,
			IstwitterActiveColor: "",
			newDriverCountryId: "all",
			countrySelected: {
				id: "all",
				name: "All (Around the world)",
			},
			twDrivers: [],
			statusActive: false,
			newDriverName: "",
			openModal: false,
			buttonDisabled: true,
			paises: [
				{
					id: "all",
					name: "All (Around the world)",
				},
				{
					id: "2dfa9ecb0179a4e4",
					name: "Perú",
				},
				{
					id: "47a3cf27863714de",
					name: "Chile",
				},
				/* {
          id: "0639360bd49a15e3",
          name: "Colombia",
        }, */
			],
			loading: false,
		};
	},
	created() {
		this.twDrivers = this.formContext.twitterKeywords;
		this.client_id = sessionStorage.getItem("client_id");
		this.twDrivers = this.twDrivers.filter((d) => d.type == "tw");
	},
	methods: {
		async addNewDriver() {
			const newDriver = {
				term: this.newDriverName.trim(),
				place_id: this.newDriverCountryId,
				country: this.paises.find((c) => c.id == this.newDriverCountryId).name,
				active: true,
			};
			if (this.twDrivers.some((d) => d.term == newDriver.term && d.place_id == newDriver.place_id)) {
				this.showWarning("Driver already added");
			} else {
				this.activeLoading(true);
				const variables = {
					context_id: this.formContext.contextId,
					driver: {
						term: newDriver.term,
						place_id: newDriver.place_id,
						active: newDriver.active,
					},
					type: "tw",
				};

				try {
					const {
						data: { addKeyword },
					} = await this.$store.dispatch("dashboard/addKeyword", variables);
					if (addKeyword.id) {
						this.twDrivers.push(addKeyword);
						this.newDriverName = "";
						this.newDriverCountryId = "all";
						this.countrySelected = {
							id: "all",
							name: "All (Around the world)",
						};
					}
				} catch (error) {
					let dataError = sessionStorage.getItem("errorMessage");
					dataError = dataError.replace("GraphQL error: ", "");
					this.showWarning(dataError);
					console.error(error);
				} finally {
					this.activeLoading(false);
				}
			}
		},
		getCountryName(place_id) {
			if (place_id) {
				const country = this.paises.find((c) => c.id == place_id);
				if (!country) {
					return "unknown";
				} else {
					return country.name;
				}
			} else {
				return "unknown";
			}
		},
		async toggleActive(item, status) {
			this.activeLoading(true);
			if (item.id) {
				const variables = {
					context_id: this.formContext.contextId,
					keyword_id: item.id,
					type: "tw",
					active: status,
				};
				try {
					const {
						data: { toggleActiveKeyword },
					} = await this.$store.dispatch("dashboard/toggleActiveKeyword", variables);
					if (toggleActiveKeyword[0].active === status) {
						this.twDrivers = this.twDrivers.map(function(k) {
							if (k.id == item.id) {
								k.active = status;
							}
							return k;
						});
					}
				} catch (error) {
					let dataError = sessionStorage.getItem("errorMessage");
					dataError = dataError.replace("GraphQL error: ", "");
					this.showWarning(dataError);
					console.error(error);
				} finally {
					this.activeLoading(false);
				}
			}
		},
		// async toggleActiveTwitter(item, status) {
		//   this.activeLoading(true);
		//   if (item.id) {
		//     // console.log('toggleActiveTwitter');
		//     const variables = {
		//       client_id: this.client_id,
		//       keyword_id: item.id,
		//       active: status,
		//     };
		//     try {
		//       const {
		//         data: { toggleTwitterKeyword },
		//       } = await this.$store.dispatch(
		//         "dashboard/toggleActiveTwitterKeyword",
		//         variables
		//       );
		//       this.twDrivers = this.twDrivers.map(function (k) {
		//         if (k.id == item.id) k.tw_active = status;
		//         return k;
		//       });
		//     } catch (error) {
		//       let dataError = sessionStorage.getItem("errorMessage");
		//       dataError = dataError.replace('GraphQL error: ', "");
		//       this.warningText = dataError;
		//       console.error(error);
		//     } finally {
		//       setTimeout(() => {
		//         this.warningText = '';
		//         sessionStorage.removeItem("errorMessage");
		//       }, 10000);
		//       this.activeLoading(false);
		//     }
		//   }
		// },
		async deleteKeyword(item) {
			if (
				this.formContext.twitterKeywords.length +
					this.formContext.facebookKeywords.length +
					this.formContext.instagramKeywords.length +
					this.formContext.linkedinKeywords.length +
					this.formContext.newspaperKeywords.length >
				1
			) {
				this.activeLoading(true);
				if (item.id) {
					const variables = {
						context_id: this.formContext.contextId,
						keyword_id: item.id,
						type: "tw",
					};
					try {
						const {
							data: { deleteKeyword },
						} = await this.$store.dispatch("dashboard/deleteKeyword", variables);
						if (deleteKeyword == "keyword deleted") {
							this.twDrivers = this.twDrivers.filter((k) => k.id !== item.id);
						} else {
							throw new Error("Error deleting keyword!");
						}
					} catch (error) {
						console.error(error);
					} finally {
						this.activeLoading(false);
					}
				}
			} else {
				this.showWarning("Context must have at least one driver");
			}
		},
		async closeIscDriverModal(driver) {
			this.openModal = false;
			if (driver) {
				if (this.twDrivers.some((d) => d.term == driver.term && d.place_id == driver.place_id)) {
					this.showWarning("Driver already added");
				} else {
					this.activeLoading(true);
					const variables = {
						context_id: this.formContext.contextId,
						keyword_id: driver.id,
						active: true,
						type: "tw",
					};
					try {
						const {
							data: { addGeoKeyword },
						} = await this.$store.dispatch("dashboard/addGeoKeyword", variables);
						if (addGeoKeyword.id) {
							addGeoKeyword.active = true;
							addGeoKeyword["geo_dashboard"] = true;
							this.twDrivers.push(addGeoKeyword);
						} else {
							throw new Error("Error attempting to add driver!");
						}
					} catch (error) {
						console.error(error);
					} finally {
						this.activeLoading(false);
					}
				}
			}
		},
		showWarning(message) {
			this.$emit("setWarningMessage", message);
		},
		activeLoading(value) {
			this.$emit("updateLoading", value);
			if (value === true) {
				this.buttonDisabled = true;
				this.loading = true;
			} else {
				this.loading = false;
				this.checkName();
			}
		},
		checkName() {
			if (this.newDriverName.trim().length > 0) {
				if (this.loading === true) {
					this.buttonDisabled = true;
				} else {
					this.buttonDisabled = false;
				}
			} else {
				this.buttonDisabled = true;
			}
		},
	},
	watch: {
		newDriverName: {
			immediate: true,
			handler: function(x) {
				this.checkName();
			},
		},
		countrySelected: {
			immediate: true,
			handler: function() {
				this.newDriverCountryId = this.countrySelected.id;
			},
		},
	},
};
</script>

<style>
.v-main__updateTwDriver {
	padding: 0px 0px 0px 0px !important;
}
</style>
