<template>
  <v-app id="dashboard">
    <v-main class="bgColorBase wrap-app">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import { mapActions } from "vuex";
export default {
  created() {
    this.addTitle('Dashboard')
    this.disabledMenuItem(true)
    this.$store.dispatch("global/setDatePickerActive", false);
  },
  async beforeCreate() {
    await this.$store.dispatch("global/geoDrivers", 'all');
  },
  methods: {
    ...mapActions("global", ["addTitle", "disabledMenuItem"]),
  }
};
</script>
<style>

</style>
