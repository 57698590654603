<template>
	<div class="context-box" style="width: 90%;">
		<v-row no-gutters>
			<v-col :sm="12" :md="12" :lg="12">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="7" :lg="8">
				<v-row>
					<v-col cols="12" class="pr-1 pl-1 pt-1">
						<input
							maxlength="80"
							type="text"
							class=" input pl-3 font12pt titleColor font-weight-bold w-100"
							placeholder="Paste url profile page..."
							v-model="newDriverPage"
						/>
						<!-- <div class="pt-1 textError ml-0" v-if="warningText != ''">
              <span><v-icon size="12" color="red">fa fa-exclamation-circle</v-icon> {{ warningText }}</span>
            </div> -->
					</v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="5" :lg="4">
				<div class="d-flex justify-end align-end">
					<v-btn
						depressed
						color="bgColorPrincipal"
						class="mainBtn mx-2"
						:disabled="buttonDisabled"
						@click="addNewDriver()"
					>
						<span class="white--text">Create</span>
					</v-btn>
					<v-btn depressed color="bgColorPrincipal" class="mainBtn" @click="openModal = true">
						<span class="white--text mr-1" style="font-size: 20px;">+</span
						><span class="white--text"> ISC Drivers</span>
					</v-btn>
				</div>
			</v-col>
			<v-row class="context-box__table">
				<v-col>
					<div class="context-box__table__header d-flex">
						<div class="col-12">
							<span class="font12pt leyendColor">Profile Page list</span>
						</div>
					</div>
					<div class="bgColorBase context-box__table__body">
						<v-virtual-scroll :bench="10" :items="allDrivers" max-height="168" item-height="42">
							<template v-slot:default="{ item }">
								<v-row
									v-bind:style="{
										backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
									}"
								>
									<v-col cols="1" align="end">
										<div>
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="item.geo_dashboard"
														size="14"
														color="#a2acc4"
														v-bind="attrs"
														v-on="on"
														>fas fa-globe</v-icon
													>
												</template>
												<span>ISC Driver</span>
											</v-tooltip>
										</div>
									</v-col>
									<v-col cols="5">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt titleColor ml-3">
												{{ item.name }}
											</span>
										</div>
									</v-col>
									<v-col cols="2" align="start">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt leyendColor">
												{{ `${item.mask}` }}
											</span>
										</div>
									</v-col>
									<v-col cols="2" align="end">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt leyendColor" v-if="item.likes">
												{{ ` ${formatLikes(item.likes)} Likes` }}
											</span>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon @click="deleteDriver(item)" size="11" color="#a2acc4"
												>fas fa-trash</v-icon
											>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon
												v-if="item.active === true"
												size="12"
												color="#00a5ff"
												@click="item.active = false"
												>fas fa-eye</v-icon
											>
											<v-icon
												v-if="item.active === false"
												size="12"
												color="#a2acc4"
												@click="item.active = true"
												>fas fa-eye</v-icon
											>
										</div>
									</v-col>
								</v-row>
							</template>
						</v-virtual-scroll>
					</div>
				</v-col>
			</v-row>
		</v-row>
		<AddIscDriverModal v-if="openModal" @closeIscDriverModal="closeIscDriverModal" :driverType="'ig'" />
	</div>
</template>

<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";
export default {
	name: "CreateInstagramDrivers",
	components: { AddIscDriverModal },
	props: ["allDriversToAdd", "allIscDriversToAdd"],
	data() {
		return {
			drivers: [],
			newDriverPage: "",
			openModal: false,
			warningText: "",
			selectedISCDrivers: [],
			buttonDisabled: true,
			paises: [
				{
					id: "all",
					name: "All (Around the world)",
				},
				{
					id: "2dfa9ecb0179a4e4",
					name: "Perú",
				},
				{
					id: "47a3cf27863714de",
					name: "Chile",
				},
				/* {
          id: "0639360bd49a15e3",
          name: "Colombia",
        }, */
			],
		};
	},
	created() {},
	methods: {
		addNewDriver: async function() {
			this.buttonDisabled = true;
			this.$emit("driverLoading", true);
			const urlRegEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
			if (!this.newDriverPage || !this.newDriverPage.match(urlRegEx)) {
				this.showWarning("The URL provided is not a valid profile page");
				this.$emit("driverLoading", false);
				return;
			}
			try {
				await this.sendUrlCheck();
			} catch (error) {
				/* if (this.newDriverPage.includes("-")) {
          const splitUrl = this.newDriverPage.split("-");
          const pageId = splitUrl[splitUrl.length - 1];
          this.newDriverPage = `https://www.instagram.com/${pageId}`;
          try {
            await this.sendUrlCheck();
          } catch (error) { */
				this.showWarning("The URL provided is not a valid profile page");
				/* }
      } else {
        this.showWarning("URL invalid");
      } */
			} finally {
				this.$emit("driverLoading", false);
			}
		},
		sendUrlCheck: async function() {
			try {
				this.buttonDisabled = true;
				const {
					data: { igPageCheck },
				} = await this.$store.dispatch("dashboard/igFanpageCheck", {
					page_url: this.newDriverPage.trim(),
				});
				if (igPageCheck && igPageCheck.public === true) {
					const newDriver = {
						page_url: this.newDriverPage.trim(),
						page_id: igPageCheck.id,
						mask: igPageCheck.mask,
						active: true,
						image: igPageCheck.image,
						name: igPageCheck.name,
						type: "ig",
						added: new Date().valueOf(),
						geo_dashboard: false,
					};
					if (this.allDriversToAdd.some((d) => d.page_id == newDriver.page_id)) {
						this.showWarning("Profile page already added");
					} else {
						//this.drivers.push(newDriver);
						//delete newDriver.likes;
						this.newDriverPage = "";
						this.$emit("driverToAdd", newDriver);
					}
				} else {
					throw new Error("Error al validar Fanpage");
				}
			} catch (error) {
				this.buttonDisabled = true;
				console.error(error);
				throw error;
			}
		},
		closeIscDriverModal(driver) {
			this.openModal = false;
			if (driver) {
				if (this.allIscDriversToAdd.some((d) => d.mask == driver.mask)) {
					this.showWarning("Driver already added");
				} else {
					const newDriver = {
						...driver,
						added: new Date().valueOf(),
					};
					this.$emit("iscDriverToAdd", newDriver);
				}
			}
		},
		showWarning: function(message) {
			this.$emit("setWarningMessage", message);
		},
		deleteDriver: function(driver) {
			if (driver.geo_dashboard) {
				this.$emit("deleteIscDriverToAdd", driver.added, "added");
			} else {
				this.$emit("deleteDriverToAdd", driver.added, "added");
			}
		},
		formatLikes(n) {
			if (n < 1e3) return n;
			if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
			if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
			if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
			if (n >= 1e12) return +(n / 1e12).toFixed(1) + " T";
		},
	},
	computed: {
		allDrivers: function() {
			return [...this.allIscDriversToAdd, ...this.allDriversToAdd].sort((a, b) => a.added - b.added);
		},
	},
	watch: {
		newDriverPage: {
			immediate: true,
			handler: function(x) {
				if (this.newDriverPage.trim().length > 0) {
					this.buttonDisabled = false;
				} else {
					this.buttonDisabled = true;
				}
			},
		},
	},
};
</script>

<style>
.v-main__fbDriver {
	padding: 0px 0px 0px 0px !important;
}

.w570 {
	width: 470px;
}
</style>
