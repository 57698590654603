<template>
	<div class="context-box" style="width: 90%;">
		<v-row no-gutters>
			<v-col :sm="12" :md="12" :lg="5" class="mb-3">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
					<v-col cols="12" class="pr-1 pl-1 pt-1">
						<input
							maxlength="40"
							type="text"
							class="input pl-3 font12pt titleColor font-weight-bold w-100"
							placeholder="Term, mention or Hashtag (One at a time)..."
							v-model="newDriverName"
						/>
						<!-- <div class="pt-1 textError ml-0" v-if="warningText != ''">
              <span><v-icon size="12" color="red">fa fa-exclamation-circle</v-icon> {{ warningText }}</span>
            </div> -->
					</v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="6" :lg="3" class="mb-3">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">Country:</span></v-col>
					<v-col cols="12" class="pr-1 pl-1 pt-1">
						<v-select
							v-model="countrySelected"
							:items="paises"
							full-width
							item-text="name"
							item-value="id"
							solo
							hide-details
							flat
							single-line
							return-object
							background-color="#f1f3f8"
							class="selectCountry font12pt titleColor font-weight-bold"
							item-color="#00a5ff"
						></v-select>
					</v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="6" :lg="4" class="mb-3">
				<v-row no-gutters>
					<v-col cols="12"><span class="invisible_">.</span></v-col>
					<v-col cols="12">
						<div class="d-flex justify-end align-end">
							<v-btn
								depressed
								color="bgColorPrincipal"
								class="mainBtn mx-2"
								:disabled="buttonDisabled"
								@click="addNewDriver()"
							>
								<span class="white--text">Create</span>
							</v-btn>
							<v-btn depressed color="bgColorPrincipal" class="mainBtn" @click="openModal = true">
								<span class="white--text mr-1" style="font-size: 20px;">+</span
								><span class="white--text"> ISC Drivers</span>
							</v-btn>
							<!-- <button class="pt-2 mt-1 pb-2 pr-5 pl-5 mr-2 bgColorPrincipal rounded" v-bind:class="{ buttonDisabled }"
                @click="addNewDriver()" :disabled="buttonDisabled">
                <span class="pl-2 pr-2 white--text"> Create </span>
              </button> -->
							<!-- <button class="pt-2 mt-1 pb-2 pr-3 pl-3 bgColorPrincipal rounded" @click="openModal = true">
                <span class="white--text"> <b>+</b> ISC Drivers </span>
              </button> -->
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="context-box__table">
			<v-col>
				<div class="context-box__table__header d-flex">
					<div class="col-12">
						<span class="font12pt leyendColor">Driver list</span>
					</div>
				</div>
				<div class="bgColorBase context-box__table__body">
					<v-virtual-scroll :bench="10" :items="allDrivers" max-height="168" item-height="42">
						<template v-slot:default="{ item }">
							<v-row
								v-bind:style="{
									backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
								}"
							>
								<v-col cols="1" align="end">
									<div>
										<v-tooltip top>
											<template v-slot:activator="{ on, attrs }">
												<v-icon
													v-if="item.geo_dashboard"
													size="14"
													color="#a2acc4"
													v-bind="attrs"
													v-on="on"
													>fas fa-globe</v-icon
												>
											</template>
											<span>ISC Driver</span>
										</v-tooltip>
									</div>
								</v-col>
								<v-col cols="5">
									<div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
										<span class="font12pt titleColor ml-10">
											{{ item.term }}
										</span>
									</div>
								</v-col>
								<v-col cols="4">
									<div>
										<span class="font12pt leyendColor">
											{{ item.country }}
										</span>
									</div>
								</v-col>
								<v-col cols="1" align="start">
									<div>
										<v-icon @click="deleteDriver(item)" size="11" color="#a2acc4"
											>fas fa-trash</v-icon
										>
									</div>
								</v-col>
								<v-col cols="1" align="start">
									<div>
										<v-icon
											v-if="item.active === true"
											size="12"
											color="#00a5ff"
											@click="item.active = false"
											>fas fa-eye</v-icon
										>
										<v-icon
											v-if="item.active === false"
											size="12"
											color="#a2acc4"
											@click="item.active = true"
											>fas fa-eye</v-icon
										>
									</div>
								</v-col>
							</v-row>
						</template>
					</v-virtual-scroll>
				</div>
			</v-col>
		</v-row>
		<AddIscDriverModal v-if="openModal" @closeIscDriverModal="closeIscDriverModal" :driverType="'tw'" />
	</div>
</template>

<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";

export default {
	name: "CreateTwitterDrivers",
	components: { AddIscDriverModal },
	props: ["allDriversToAdd", "allIscDriversToAdd"],
	data() {
		return {
			buttonDisabled: true,
			drivers: [],
			newDriverName: "",
			newDriverCountryId: "all",
			countrySelected: {
				id: "all",
				name: "All (Around the world)",
			},
			openModal: false,
			selectedISCDrivers: [],
			paises: [
				{
					id: "all",
					name: "All (Around the world)",
				},
				{
					id: "2dfa9ecb0179a4e4",
					name: "Perú",
				},
				{
					id: "47a3cf27863714de",
					name: "Chile",
				},
				/* {
          id: "0639360bd49a15e3",
          name: "Colombia",
        }, */
			],
			// warningText: "",
		};
	},
	created() {
		//CreateData.data.twitterKeywords = this.drivers;
		//CreateData.data.iscDrivers = this.selectedISCDrivers;
	},
	methods: {
		addNewDriver: function() {
			if (!this.newDriverName) return;
			const newDriver = {
				term: this.newDriverName.trim(),
				place_id: this.newDriverCountryId,
				country: this.paises.find((c) => c.id == this.newDriverCountryId).name,
				active: true,
				added: new Date().valueOf(),
				geo_dashboard: false,
				type: "tw",
			};

			if (this.allDriversToAdd.some((d) => d.term == newDriver.term && d.place_id == newDriver.place_id)) {
				this.showWarning("Driver already added");
			} else {
				//this.drivers.push(newDriver);
				this.newDriverName = "";
				this.newDriverCountryId = "all";
				this.countrySelected = {
					id: "all",
					name: "All (Around the world)",
				};
				this.$emit("driverToAdd", newDriver);
			}
		},
		closeIscDriverModal(driver) {
			this.openModal = false;
			if (driver) {
				if (this.allIscDriversToAdd.some((d) => d.term == driver.term && d.place_id == driver.place_id)) {
					this.showWarning("Driver already added");
				} else {
					const newDriver = {
						...driver,
						added: new Date().valueOf(),
						country: this.paises.find((c) => c.id == driver.place_id).name,
						geo_dashboard: true,
					};
					this.$emit("iscDriverToAdd", newDriver);
				}
			}
		},
		deleteDriver: function(driver) {
			if (driver.geo_dashboard) {
				this.$emit("deleteIscDriverToAdd", driver.added, "added");
			} else {
				this.$emit("deleteDriverToAdd", driver.added, "added");
			}
		},
		showWarning(message) {
			this.$emit("setWarningMessage", message);
		},
	},
	computed: {
		allDrivers: function() {
			return [...this.allIscDriversToAdd, ...this.allDriversToAdd].sort((a, b) => a.added - b.added);
		},
	},
	watch: {
		newDriverName: {
			immediate: true,
			handler: function(x) {
				if (this.newDriverName.trim().length > 0) {
					this.buttonDisabled = false;
				} else {
					this.buttonDisabled = true;
				}
			},
		},
		countrySelected: {
			immediate: true,
			handler: function() {
				this.newDriverCountryId = this.countrySelected.id;
			},
		},
	},
};
</script>

<style>
.mainBtn {
	text-transform: unset !important;
	font-size: 12px;
	letter-spacing: 0.5px;
	font-family: Helvetica, Arial, sans-serif;
}
</style>
