import Router from "vue-router";
import Dashboard from "@/views/Dashboard";
import YourBoards from "@/views/YourBoards";
import ISCBoards from "@/views/ISCBoards";
export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/dashboard",
      name: "home",
      component: Dashboard,
      children: [
        {
          path: "your-boards",
          name: "Your Boards",
          component: YourBoards,
        },
        {
          path: "isc-boards",
          name: "ISC Boards",
          component: ISCBoards,
        },
      ],
      redirect: "/dashboard/your-boards",
    },
  ],
});
