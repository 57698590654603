<template>
  <div class="main-boards__content-IscBoards">
    <!-- AQUI VA ISC_BOARDS -->
    <div class="main-boards__content_grid">
      <div class="main-boards__content_grid_item bg-white">
        <v-row>
          <v-col lg="3"
            md="4"
            sm="6" v-for="board in ISCBoards" :key="board.id">
            <v-card
              class="grid-item__content position-relative elevation-2 pt-4"
            >
              <div
                class="grid-item__content--ribbon position-absolute rounded-0"
              ></div>
              <div class="grid-item__content--title mb-5">
                <h2
                  class="
                    text-capitalize text-center
                    font15pt
                    titleColor
                    font-weight-bold
                  "
                >
                  {{ board.name }}
                </h2>
              </div>
              <div
                class="
                  grid-item__content--values
                  text-center
                  d-flex
                  flex-column
                  mt-5
                  pt-3
                "
              >
                <span
                  class="
                    grid-item__content--values--days
                    font10pt
                    titleColor
                    text-capitalize
                  "
                  >last 15 days</span
                >
                <span
                  class="
                    grid-item__content--values--count
                    text-capitalize
                    font37pt
                    titleColor
                    font-weight-bold
                  "
                  >{{
                    convertNumberTokFormat(
                      board.dashboard_metrics.count_samples
                    )
                  }}</span
                >
                <span
                  class="
                    grid-item__content--values--type
                    text-capitalize
                    font12pt
                    titleColor
                  "
                  >Samples</span
                >
              </div>
              <div class="grid-item__content--graph px-2" style="min-height: 100px;">
                <apexchart
                  type="area"
                  height="100"
                  :options="chartOptionsIscBoards"
                  :series="[
                    {
                      name: 'samples',
                      data: board.dashboard_metrics.histogram,
                    },
                  ]"
                >
                </apexchart>
              </div>
              <div class="grid-item__content--buttom pa-1">
                <button
                  class="button-overview bgColorBase"
                  @click="toOverview(board.id)"
                >
                  <v-icon size="14">fas fa-eye</v-icon>
                </button>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  props: ["countryId"],
  data() {
    return {
      title: "TOOO",
      ISCBoards: [],
      chartOptions: {
        chart: {
          type: "area",
          height: 100,
          width: "100%",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        theme: {
          mode: "light",
          monochrome: {
            enabled: true,
            color: "#FFFFFF",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          opposite: true,
          showAlways: false,
        },
        legend: {
          horizontalAlign: "left",
        },
      },
      chartOptionsIscBoards: {
        chart: {
          type: "area",
          height: 100,
          width: "100%",
          sparkline: {
            enabled: true
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        theme: {
          mode: "light",
          monochrome: {
            enabled: true,
            color: "#00a5ff",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          opposite: true,
          showAlways: false,
        },
        legend: {
          horizontalAlign: "left",
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("global", {
      yourBoard: "getYourBoard",
    }),
    /* ...mapGetters('dashboard',{
        overviews: 'overviews',
    }), */
    /* items() {
      return this.overviews;
    }, */
  },
  mounted() {
    this.changeYourBoard(false);
  },
  async created() {
    //this.overviews('ac4a5106-3b2e-4c9e-996f-988ff6fdcc65');
    await this.getIscBoards();
    this.disabledMenuItem(true);
  },
  methods: {
    /* ...mapActions("dashboard", ["overviews"]), */
    ...mapActions("global", [
      "setContextBoard",
      "removeContextBoard",
      "changeYourBoard",
      "disabledMenuItem",
    ]),
    convertNumberTokFormat(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    async getIscBoards() {
      try {
        this.loading = true;
        const {
          data: { geoDashboards },
        } = await this.$store.dispatch("dashboard/geoDashboards", {
          country_id: this.countryId,
        });
        if (geoDashboards.length > 0) {
          const boardsItems = [];
          geoDashboards.map((i) => {
            boardsItems.push({
              id: i.id,
              name: i.name,
              dashboard_metrics: {
                count_samples: i.dashboard_metrics.count_samples,
                histogram: i.dashboard_metrics.histogram,
                sentiment: i.dashboard_metrics.sentiment,
              },
            });
          });
          this.ISCBoards = boardsItems;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    toOverview(context) {
      this.$router.push("/overview/" + context);
      this.setContextBoard(context);
    },
  },
  watch: {
    countryId: {
      immediate: true,
      handler: async function () {
        await this.getIscBoards();
      },
    },
  },
};
</script>
