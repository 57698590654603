<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container update-width">
          <button class="
              bgColorPrincipal
              modal-icon-close
              px-2
              py-1
              mr-3
              mt-3
              rounded
            " @click="$emit('closeIscDriverModal')">
            <v-icon color="#fff" size="16"> fas fa-times </v-icon>
          </button>
          <div class="centerDiv">
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate v-if="loading">
            </v-progress-circular>
          </div>
          <div v-if="!loading">
            <v-row>
              <h1 class="font18pt titleColor font-weight-bold">
                Add ISC Drivers
              </h1>
            </v-row>
            <div class="pt-3">
              <span class="font12pt leyendColor">Select Country</span>
              <select class="input pl-3 font12pt titleColor font-weight-bold w-100" v-model="selectedCountry"
                @change="selectedIndustry = {}">
                <option v-for="country in ISCDrivers" :key="country.place_id" :value="country">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="pt-3">
              <span class="font12pt leyendColor">Select Industry</span>
              <select class="input pl-3 font12pt titleColor font-weight-bold w-100" v-model="selectedIndustry"
                @change="selectedDriver = undefined" :disabled="!selectedCountry.industries">
                <option v-for="(industry, i) in selectedCountry.industries" :key="i" :value="industry">
                  {{ industry.name }}
                </option>
              </select>
            </div>
            <div class="pt-3">
              <span class="font12pt leyendColor">Select Driver</span>
              <select class="input pl-3 font12pt titleColor font-weight-bold w-100" v-model="selectedDriver"
                :disabled="selectedIndustry ? (selectedIndustry.name ? false : true) : true">
                <option v-for="driver in filteredIndustries" :key="driver.id" :value="driver">
                  {{ driver.term || driver.name }}
                </option>
              </select>
            </div>
            <v-row class="pt-3">
              <v-col class="text-right textError">
                {{ warningText }}
                <button class="bgColorPrincipal pt-2 pb-2 pl-5 pr-5 ml-3 rounded"
                  @click="$emit('closeIscDriverModal', selectedDriver)" :disabled="!selectedDriver">
                  <span class="mr-1 font13pt white--text">Add</span>
                </button>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
//import paises from '../../helpers/paises';

export default {
  name: "AddIscDriverModal",
  props: [/* "ISCDrivers", */ "driverType"],
  components: {},
  data() {
    return {
      model: "",
      name: "",
      warningText: "",
      selectedCountry: {},
      selectedIndustry: {},
      selectedDriver: undefined,
      loading: false,
      ISCDrivers: [],
    };
  },
  async mounted() {
    const geoDrivers = this.$store.getters["global/geoDrivers"];
    if (geoDrivers) {
      /* return geoDrivers; */
      this.loading = false;
    } else {
      this.loading = true;
      await this.$store.dispatch("global/geoDrivers", 'all');
    }
  },
  methods: {
    showWarning: function (message) {
      this.warningText = message;
    },
  },
  computed: {
    filteredIndustries: function () {
      if (this.selectedIndustry && this.selectedIndustry.drivers) {
        return this.selectedIndustry.drivers.filter(
          (d) => d.type == this.driverType
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    "$store.state.global.geoDrivers": {
      immediate: true,
      handler: function () {
        const geoDrivers = this.$store.getters["global/geoDrivers"];
        if (geoDrivers) {
          const data = geoDrivers;
          for (const country of Object.keys(data)) {
            const object = { ...data[country] };
            object['name'] = country;
            object['industries'] = [];
            for (const category of Object.keys(data[country].categories)) {
              object.industries.push({
                name: category,
                drivers: data[country].categories[category],
              });
            }
            delete object.categories;
            this.ISCDrivers.push(object);
            this.loading = false;
          }
        } else {
          this.loading = true;
        }
      },
    },
  },
};
</script>

<style>
.input {
  height: 35px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: inset 5px 6px 44px -19px #c5d0e4;
}

.update-width {
  width: 30%;
}

.centerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>