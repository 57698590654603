<template>
	<div class="context-box" style="width: 90%;">
		<v-row no-gutters>
			<v-col :sm="12" :md="12" :lg="12">
				<v-row no-gutters>
					<v-col cols="12"><span class="font12pt leyendColor">What:</span></v-col>
				</v-row>
			</v-col>
			<v-col :sm="6" :md="9" :lg="10">
				<v-row>
					<v-col class="pr-1 pl-1 pt-1">
						<v-select
							label="Country"
							:disabled="allNewspapers.length == 0"
							:items="nwsCountries"
							v-model="nwsCountrySelected"
							:loading="allNewspapers.length == 0"
							hide-details
							@change="changeSelectPlace(nwsCountrySelected)"
						></v-select>
					</v-col>
					<v-col class="pr-1 pl-1 pt-1">
						<v-select
							label="Newspaper"
							:disabled="nwsCountrySelected == ''"
							:items="nwsNames"
							v-model="nwsNameSelected"
							:loading="allNewspapers.length == 0"
							hide-details
							@change="changeSelectName(nwsNameSelected)"
						></v-select>
					</v-col>
					<v-col class="pr-1 pl-1 pt-1">
						<v-select
							label="Section"
							:disabled="nwsNameSelected == ''"
							:items="nwsUrls"
							v-model="nwsUrlSelected"
							:loading="allNewspapers.length == 0"
							hide-details
							@change="changeSelectUrl(nwsUrlSelected)"
						></v-select>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col class="pr-1 pl-1 pt-1">
						<div class="pt-1 textError ml-0" v-if="warningText != ''">
							<span
								><v-icon size="12" color="red">fa fa-exclamation-circle</v-icon> {{ warningText }}</span
							>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col>
				<div class="d-flex justify-end align-end">
					<v-btn
						depressed
						color="bgColorPrincipal"
						class="mainBtn mx-2 mt-2"
						:disabled="nwsToAdd === ''"
						@click="addNewDriver()"
					>
						<span class="white--text">Add</span>
					</v-btn>
				</div>
			</v-col>
			<v-row class="context-box__table">
				<v-col>
					<div class="context-box__table__header d-flex">
						<div class="col-12">
							<span class="font12pt leyendColor">Profile Page list</span>
						</div>
					</div>
					<div class="bgColorBase context-box__table__body">
						<v-virtual-scroll :bench="10" :items="allDriversToAdd" max-height="168" item-height="42">
							<template v-slot:default="{ item }">
								<v-row
									v-bind:style="{
										// backgroundColor: item.geo_dashboard ? '#fbfbfb' : 'inherit',
									}"
								>
									<v-col cols="1" align="end">
										<div>
											<v-tooltip top>
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-if="item.geo_dashboard"
														size="14"
														color="#a2acc4"
														v-bind="attrs"
														v-on="on"
														>fas fa-globe</v-icon
													>
												</template>
												<span>ISC Driver</span>
											</v-tooltip>
										</div>
									</v-col>
									<v-col cols="5">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt titleColor ml-3">
												{{ item.newspaper_name }}
											</span>
										</div>
									</v-col>
									<v-col cols="4" align="start">
										<div
											style="
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
										>
											<span class="font12pt leyendColor">
												{{
													`${
														item.page_url.split("/").slice(-1) == ""
															? item.page_url
																	.split("/")
																	.slice(-2, -1)
																	.toString()
															: item.page_url
																	.split("/")
																	.slice(-1)
																	.toString()
													}`
												}}
											</span>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon @click="deleteDriver(item)" size="11" color="#a2acc4"
												>fas fa-trash</v-icon
											>
										</div>
									</v-col>
									<v-col cols="1" align="start">
										<div>
											<v-icon
												v-if="item.active === true"
												size="12"
												color="#00a5ff"
												@click="item.active = false"
												>fas fa-eye</v-icon
											>
											<v-icon
												v-if="item.active === false"
												size="12"
												color="#a2acc4"
												@click="item.active = true"
												>fas fa-eye</v-icon
											>
										</div>
									</v-col>
								</v-row>
							</template>
						</v-virtual-scroll>
					</div>
				</v-col>
			</v-row>
		</v-row>
	</div>
</template>

<script>
import AddIscDriverModal from "@/components/context/AddIscDriverModal.vue";
export default {
	name: "CreateNewsPaperDrivers",
	components: { AddIscDriverModal },
	props: ["allDriversToAdd"],
	data() {
		return {
			allNewspapers: [],
			nwsCountries: [],
			nwsNames: [],
			nwsUrls: [],
			nwsCountrySelected: "",
			nwsNameSelected: "",
			nwsUrlSelected: "",
			nwsToAdd: "",
			warningText: "",
			paises: [
				{
					id: "2dfa9ecb0179a4e4",
					name: "Perú",
				},
				{
					id: "47a3cf27863714de",
					name: "Chile",
				},
			],

			/* nwsPlaceSelected: "",
      nwsNameSelected: "",
      nwsSelected: "",
      allNewspapersLoading: false,
      firstFilterDisabled: true,
      newspaperAdd: '',
      newspaperUrlAdd: '',
      newspaperPlaceAdd: '',
      drivers: [],
      allNewspaperLoad: [],
      newspaper: [],
      newspaperUrl: [],
      newspaperPlace: [],
      newDriverPage: "",
      openModal: false,
      selectedISCDrivers: [],
      buttonDisabled: true, */
		};
	},
	async created() {
		await this.getNewspaperList();
	},
	methods: {
		changeSelectPlace(countryName) {
			this.nwsToAdd = "";
			this.nwsNameSelected = "";
			this.nwsUrlSelected = "";
			this.nwsCountrySelected = countryName;
			this.nwsNames = this.allNewspapers
				.filter((n) => {
					if (n.place_id == this.getPlaceId(this.nwsCountrySelected)) return n;
				})
				.map((e) => e.newspaper_name);

			/* this.newspaper = [];
      this.newspaperUrl = [];
      this.nwsSelected = "";
      this.nwsNameSelected = "";
      const pais = this.paises.find((p) => { if (p.name == countryName) return p });
      this.nwsPlaceSelected = pais.id;
      const nwsFilteredByPlace = this.allNewspaperLoad.filter((n) => { if (n.place_id == pais.id) return n; });
      nwsFilteredByPlace.map((e) => this.newspaper.push(e.newspaper_name)); */
		},
		changeSelectName(newspaperName) {
			this.nwsToAdd = "";
			this.nwsUrlSelected = "";
			this.nwsNameSelected = newspaperName;
			this.nwsUrls = this.allNewspapers
				.filter((n) => {
					if (
						n.place_id == this.getPlaceId(this.nwsCountrySelected) &&
						n.newspaper_name == this.nwsNameSelected
					)
						return n;
				})
				.map((e) => e.page_url);

			/* this.nwsNameSelected = newspaperName;
      this.nwsSelected = this.newspaperUrl;
      const nwsFilteredByName = this.allNewspaperLoad.filter((n) => { if (n.place_id == this.nwsPlaceSelected && n.newspaper_name == newspaperName) return n; });
      nwsFilteredByName.map((e) => this.newspaperUrl.push(e.page_url)); */
		},
		changeSelectUrl(newspaperUrl) {
			this.nwsUrlSelected = newspaperUrl;
			this.nwsToAdd = this.allNewspapers.filter((n) => {
				if (
					n.place_id == this.getPlaceId(this.nwsCountrySelected) &&
					n.newspaper_name == this.nwsNameSelected &&
					n.page_url == this.nwsUrlSelected
				)
					return n;
			})[0];

			//this.nwsSelected = this.allNewspaperLoad.find(n => n.place_id == this.nwsPlaceSelected && n.newspaper_name == this.nwsNameSelected && n.page_url == newspaperUrl);
		},
		getPlaceId(countryName) {
			return this.paises.find((p) => {
				if (p.name == countryName) return p.id;
			}).id;
		},
		async getNewspaperList() {
			try {
				this.allNewspapers = [];
				const {
					data: { getNewspapersDrivers },
				} = await this.$store.dispatch("dashboard/getNewsPaperList");
				if (getNewspapersDrivers) {
					this.allNewspapers = getNewspapersDrivers;
					this.fillSelects();
				} else {
					throw new Error("Error al validar periódico");
				}
				/* this.allNewspapersLoading = true;
        this.buttonDisabled = true;
        this.firstFilterDisabled = true;

        const {
          data: { getNewspapersDrivers },
        } = await this.$store.dispatch("dashboard/getNewsPaperList");
        if (getNewspapersDrivers) {
          this.allNewspaperLoad = getNewspapersDrivers;
          this.fillSelects();
          this.allNewspapersLoading = false;
        } else {
          throw new Error("Error al validar diario");
        } */
			} catch (error) {
				this.allNewspapers = [];
				this.$emit("setWarningMessage", "Error getting newspaper list");
				console.error(error);
				throw error;
			}
		},
		fillSelects() {
			const nwsPlacesTemp = [];
			this.allNewspapers.map((d) => {
				nwsPlacesTemp.push(d.place_id);
				this.nwsNames.push(d.newspaper_name);
				this.nwsUrls.push(d.page_url);
			});
			nwsPlacesTemp.map((place) => {
				const pais = this.paises.find((p) => {
					if (p.id == place) return p.name;
				});
				this.nwsCountries.push(pais.name);
			});
		},
		addNewDriver() {
			if (this.nwsToAdd != "") {
				const driverToAdd = Object.assign({}, this.nwsToAdd);
				driverToAdd.active = true;
				driverToAdd.type = "nws";
				/* let DriverToAdd = this.filterNewPaperToAdd();
        this.newspaperAdd = '';
        this.newspaperUrlAdd = '';
        this.newspaperPlaceAdd = '';
        this.drivers.push(DriverToAdd);
        let itemToEmit = {
          id: DriverToAdd.id,
          active: DriverToAdd.active
        } */

				if (this.allDriversToAdd.length > 0) {
					const driverExist = this.allDriversToAdd.find((d) => d.id == driverToAdd.id);
					if (driverExist) {
						this.showWarning("Newspaper already added");
						return;
					}
				}
				this.$emit("driverToAdd", driverToAdd);
				this.cleanSelects();
			}
		},
		cleanSelects() {
			this.nwsToAdd = "";
			this.nwsNameSelected = "";
			this.nwsUrlSelected = "";
			this.nwsCountrySelected = "";
			this.fillSelects();
			/* this.nwsSelected = '';
      this.nwsNameSelected = '';
      this.nwsPlaceSelected = '';
      this.fillSelects();
      this.newspaper = [];
      this.newspaperUrl = [];
      this.newspaperPlaceAdd = 0;
      this.newspaperAdd = 0; */
		},
		showWarning: function(message) {
			this.$emit("setWarningMessage", message);
		},
		deleteDriver: function(driver) {
			this.$emit("deleteDriverToAdd", driver.id, "id");
		},
	},
	computed: {
		/* allDrivers: function () {
      return [...this.drivers].sort(
        (a, b) => a.added - b.added
      );
    }, */
	},
	watch: {
		/* allDriversToAdd: {
      immediate: true,
      handler: function (x) {
        console.log(x);
      },
    }, */
	},
};
</script>

<style>
.v-main__fbDriver {
	padding: 0px 0px 0px 0px !important;
}

.w570 {
	width: 470px;
}
</style>
