<template>
  <v-container>
    <v-row style="padding-left: 5rem;">
      <div class="w-100" style="margin-top:150px">
        <v-col cols="12" class="text-right">
          <div class="d-inline-flex selectBox" v-if="!yourBoard">
            <div class="d-flex justify-center align-center">
            <v-img
              v-if="country == 'Peru'"
              width="35"
              height="35"
              :src="peruImage"
              class=""
            ></v-img>
            <v-img
              v-if="country == 'Chile'"
              width="35"
              height="35"
              :src="chileImage"
            ></v-img>
            </div>
            <div style="padding: 0px 12px;">
              <v-select
                :items="countries"
                v-model="defaultSelect"
                item-text="name"
                class="ml-3"
                item-value="id"
                return-object
                @change="changeCountry"
              ></v-select>
            </div>
          </div>
          <div style="padding: 0px 12px;">
            <v-btn
            v-if="yourBoard"
            color="primary"
            class="mb-3"
            @click="modalOpen = true"
            >Create boards</v-btn
            >
          </div>
        </v-col>
        <v-col cols="12">
          <div class="main-boards">
            <router-view
              :countryId="countryId"
              :contextCreated="contextCreated"
              @updateContextCreated="updateContextCreated"
            />
          </div>
        </v-col>
      </div>
    </v-row>
    <!-- <v-row>
      <v-col>
        <Board :refetch="refetch" :countryId="countryId" />
      </v-col>
    </v-row> -->
    <CreateContext v-if="modalOpen" @close="handleCreateModalClose" />
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import CreateContext from "@/components/context/CreateContext.vue";

export default {
  name: "Dashboard",
  components: {
    CreateContext,
  },
  data() {
    return {
      modalOpen: false,
      show: true,
      countries: [
        {
          id: "2dfa9ecb0179a4e4",
          name: "Peru",
        },
        {
          id: "47a3cf27863714de",
          name: "Chile",
        },
      ],
      countryId: "2dfa9ecb0179a4e4",
      country: "Peru",
      defaultSelect: { id: "2dfa9ecb0179a4e4" },
      contextCreated: false,
      peruImage:  require("@/assets/pe.png"),
      chileImage: require("@/assets/cl.png"),
    };
  },
  created() {
    this.disabledMenuItem(true);
  },
  methods: {
    ...mapActions("global", ["addTitle", "disabledMenuItem"]),
    /* ...mapActions("dashboard", ["overviews"]), */
    handleCreateModalClose(newContext) {
      this.modalOpen = false;
      if (newContext) {
        this.contextCreated = true;
        //this.overviews('ac4a5106-3b2e-4c9e-996f-988ff6fdcc65');
      }
    },
    changeCountry(e) {
      this.countryId = e.id;
      this.country = e.name;
    },
    updateContextCreated(status) {
      this.contextCreated = status;
    },
  },
  computed: {
    ...mapGetters("global", {
      yourBoard: "getYourBoard",
    }),
    /* ...mapGetters('dashboard',{
        over: 'overviews',
    }), */
  },
};
</script>

<style>
.main-boards__content-yourBoards__info__boxsentiment-item {
  height: 5px;
}
.main-boards__content-yourBoards__gauge {
  margin-bottom: -40px;
}
.grid-item__content--values__graph {
  min-height: 50px;
}
.alerticon {
  top: -15px;
  right: -10px;
  background-color: red;
  line-height: 20px;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  color: #fff;
  z-index: 1;
}
.selectBox {
  width: 150px;
}
</style>
